#view1 {
  /* background-image: url('../assets/91.jpg'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
}

#view2 {
  background-color: #F4FAFC !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 100vh;

}

#footer {
  background: #424f95 !important;
}

/* #classicformpage .gradient {
  background: -webkit-linear-gradient(45deg,
      rgba(0, 0, 0, 0.7),
      rgba(72, 15, 144, 0.4) 100%);
  background: -webkit-gradient(linear,
      45deg,
      from(rgba(0, 0, 0, 0.7), rgba(72, 15, 144, 0.4) 100%));
  background: linear-gradient(45deg,
      rgba(0, 0, 0, 0.7),
      rgba(72, 15, 144, 0.4) 100%);
} */

/* #classicformpage .card {
  background-color: #F4FAFC;
}
 */
#classicformpage h6 {
  line-height: 1.7;
}

#classicformpage .navbar {
  transition: 0.5s ease-in-out, padding 0.5s ease-in-out;
}

#classicformpage .top-nav-collapse {

  background: white !important;
}

.navbar-toggler {

  background-color: red;
}

@media (max-width: 768px) {
  .navbar-collapse {
    background-color: white;
  }

  #classicformpage .navbar:not(.top-nav-collapse) {
    background: white !important;
  }
}

#spaceBetween {
  margin: 10px;
}